import { Subject } from 'rxjs';

export type IAlertType = 'success' | 'error' | 'info' | 'warning';

export enum ALERT_TYPE {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export interface IAlert {
  type: IAlertType;
  message: string;
  id?: symbol;
}
const alertSubject = new Subject<IAlert[]>();
let messages: Array<IAlert> = [];
const success = (message: string) => {
  alert({ type: ALERT_TYPE.SUCCESS, message });
};

const error = (message: string) => {
  alert({ type: ALERT_TYPE.ERROR, message });
};

const info = (message: string) => {
  alert({ type: ALERT_TYPE.INFO, message });
};
const infoKeep = (message: string) => {
  messages.push({ type: ALERT_TYPE.INFO, message , id: Symbol(message) });
  alertSubject.next(messages);
};


const warn = (message: string) => {
  alert({ type: ALERT_TYPE.WARNING, message });
};

const onAlert = () => {
  return alertSubject.asObservable();
};

const fade = (alert: IAlert) =>
  setTimeout(() => {
    remove(alert);
  }, 5000);

// add message
const alert = (alert: IAlert) => {
  const message = { ...alert, id: Symbol(alert.message) };
  messages.push(message);
  alertSubject.next(messages);
  fade(message);
};

const remove = (alert: IAlert) => {
  messages = messages.filter(eAlert => eAlert.id !== alert.id);
  alertSubject.next(messages);
};
// clear alerts
const clear = () => {
  messages = [];
  alertSubject.next(messages);
};

export const alertService = {
  onAlert,
  success,
  error,
  info,
  infoKeep,
  warn,
  alert,
  clear,
  remove,
};
