import React, { FC, ReactNode } from 'react';
import ReactModal from 'react-modal';

const customStyles = {
  // content: {
  //   top: '50%',
  //   left: '50%',
  //   right: 'auto',
  //   bottom: 'auto',
  //   marginRight: '-50%',
  //   transform: 'translate(-50%, -50%)',
  // },
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

  },
  overlay : {
    zIndex: 20000,
  }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root');

interface Props {
  open?: boolean;
  children?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  closeModal?: any;
}

const Modal: FC<Props> = ({ open = false, children, closeModal }) => {
  if (!open) {
    return null;
  }
  return (
    <ReactModal
      isOpen={open}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
