import React, { FC, useState } from 'react';
import './index.css';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import Upload from '../../components/Upload';

const UPDATE_BANNER = gql`
  mutation updateBanner($input: UpdateBannerInput!) {
    updateBanner(input: $input) {
      id
    }
  }
`;

const CREATE_BANNER = gql`
  mutation createBanner($input: CreateBannerInput!) {
    createBanner(input: $input) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AddOrEditCategory: FC<any> = ({ data, close }) => {
  const [updateReq] = useMutation(UPDATE_BANNER, { errorPolicy: 'all' });
  const [createReq] = useMutation(CREATE_BANNER, { errorPolicy: 'all' });
  const isUpdate = data.id !== undefined;
  const { register, handleSubmit } = useForm();
  const [imgUrl, setImgUrl] = useState(data.url);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    const input = {
      id: data.id,
      ...(isUpdate && {
        isDeleted:
          values.isDeleted === 'true' || values.isDeleted === true
            ? true
            : false,
      }),
      description: values.description || data.description,
      url: imgUrl || data.url,
    };
    if (isUpdate) {
      const result = await updateReq({
        variables: {
          input,
        },
      });
      if (
        result.data &&
        result.data.updateBanner &&
        result.data.updateBanner.id
      ) {
        console.log('result', result);
        close();
      }
    } else {
      const result = await createReq({
        variables: {
          input,
        },
      });
      if (
        result.data &&
        result.data.createBanner &&
        result.data.createBanner.id
      ) {
        console.log('result', result);
        close();
      }
    }
  };


  const onUploadSuccess = (url: string) => {
    if (url) {
      setImgUrl(url);
    }
  };


  return (
    <section>
      <div className="container">
        <div className="contactinfo">
          <div>
            <span style={{ color: '#fff' }}>
              {isUpdate ? 'Edit Banner:' : 'Create Banner:'}
            </span>
            <h2> {data.name}</h2>

            <div className="slots-container"></div>
          </div>
        </div>
        <div className="contactForm">
          <div className="formBox">

            <div className="inputBox w50  ">
              <input
                type="text"
                name="description"
                ref={register({ required: false, maxLength: 31 })}
                defaultValue={data.description}
                maxLength={30}
              />

              <span>description</span>
            </div>

            <Upload
              register={register}
              onUploadSuccess={onUploadSuccess}
              existingUrl={data.imageUrl}
            />

            {isUpdate && (
              <div className="inputBox w100  ">
                <input
                  type="checkbox"
                  placeholder="Delete this"
                  name="isDeleted"
                  className="styled-checkbox"
                  ref={register}
                  id="styled-checkbox-2"
                  defaultChecked={data.isDeleted}
                />
                <label htmlFor="styled-checkbox-2">
                  {data.isDeleted ? 'Enable' : 'Delete'}
                </label>
              </div>
            )}

            <div
              className="inputBox w100  "
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <button onClick={handleSubmit(onSubmit)}>save</button>
              <button onClick={close}>close</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddOrEditCategory;
