import React, { FC, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import styled from 'styled-components';
import VariantProduct from '../variantProduct';
import { useParams } from 'react-router-dom';
import Modal from '../../components/Modal';
import AddOrEditProduct from '../add-or-edit-product';

const Container = styled.div``;

const Title = styled.h1`
  font-weight: 500;
  color: ${({ theme }) => theme.textColor};
  font-size: 1.3rem;
  display: flex;
  align-items: center;
`;

const EXCHANGE_RATES = gql`
  query getVariantProducts($baseProduct: String) {
    variantProducts(baseProduct: $baseProduct, filters: {}) {
      id
      isDeleted
      name
      availableQuantity
      price
      salePrice
      imageUrl
      availableTimings {
        from
        to
      }
      netWt
      grossWt
      categoryId
      productId
      description
      promotionalProduct
    }
  }
`;

const VariantProducts: FC = () => {
  const [getVariantProds, res] = useLazyQuery(EXCHANGE_RATES);

  const { categoryId, subCategoryId } = useParams<{
    categoryId: string;
    subCategoryId: string;
  }>();

  useEffect(() => {
    if (subCategoryId) {
      getVariantProds({
        variables: {
          baseProduct: subCategoryId,
        },
      });
    }
  }, [categoryId, subCategoryId, getVariantProds]);

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  if (res.loading || !res.data) return <p>Loading...</p>;
  if (res.error) return <p>Error :(</p>;

    return (
      <>
<Modal open={modalIsOpen} closeModal={closeModal}>
        <AddOrEditProduct
          data={{ categoryId, productId: subCategoryId }}
          close={closeModal}
        />
      </Modal>

        <div>
          <div className="page-header">
            <h3 className="page-title"> Products </h3>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                {/* <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Tables</a></li>
            <li className="breadcrumb-item active" aria-current="page">Basic tables</li> */}
              </ol>
            </nav>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="row ">
                    <div className="col-lg-8">
                      <h4 className="card-title">Manage Products</h4>
                    </div>
                    <div className="col-lg-4 text-right">
                      <button
                        className="btn btn-dark btn-fw btn-sm"
                        onClick={openModal}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <p className="card-description">
                    {' '}
                    Click <code>edit</code> to manage Products.
                  </p>

                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>grossWt/netWt</th>
                          <th>image</th>
                          <th>note</th>
                          <th>mrp</th>
                          <th>salePrice</th>
                          <th>Promotional Product</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {res.data.variantProducts.map((category: any) => (
                          <VariantProduct data={category} key={category.id} />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );

};

export default VariantProducts;
