import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { createSelector } from 'reselect'

interface IUpdateOrder {
  orderId: string
  updatedData: IOrder
}
export interface IOrder {
  id: string;
  scheduledTo: string
}
interface IOrderState {
  items: { [orderId: string]: IOrder };
  cursor?: string;
  isLoading?: boolean;
}

export const ordersInitialState: IOrderState = {
  items: {},
  cursor: null,
  isLoading: false
};

export enum OrderStatus {
  INITIATED = 'INITIATED',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  SCHEDULED = 'SCHEDULED',
  DELIVERY_STARTED = 'DELIVERY_STARTED',
}

export const ordersReducer = {
  addCurrentOrders(state, action: PayloadAction<{ items: Array<IOrder>, cursor?: string }>) {
    action.payload.items.forEach(order => {
      state.items[order.id] = order
    })
    state.cursor = action.payload.cursor

    state.isLoading = false;

  },
  addNewOrders(state, action: PayloadAction<{ items: Array<IOrder> }>) {
    action.payload.items.forEach(order => {
      state.items[order.id] = order
    })
  },
  updateOrder(state, action: PayloadAction<IUpdateOrder>) {
    state.isLoading = false;
    state.items[action.payload.orderId] = action.payload.updatedData;
  }, // when the admin wants to get latest order information - implemented later
  clearAll(state, action: PayloadAction<string>) {
    state.items = {}
    state.cursor = null
    // when want to refetch all the orders - implemented later
  },
  setLoading(state, action: PayloadAction<string>) {
    state.isLoading = true;
  }
};

export const ordersSlice = createSlice({
  name: 'orders', // name used in action types
  initialState: ordersInitialState, // initial state for the reducer
  reducers: ordersReducer,
});

export const {
  updateOrder,
  addCurrentOrders,
  addNewOrders,
  setLoading,
  clearAll
} = ordersSlice.actions

export default ordersSlice.reducer

export const selectDisplayOrders = (fromDate, toDate) => createSelector(
  (state: { orders: IOrderState }) => state.orders,
  (orderState) => {

    return Object.values(orderState.items).filter((eachOrder) => fromDate <= dayjs(eachOrder.scheduledTo, 'YYYY-MM-DD-HH:mm').valueOf() && dayjs(eachOrder.scheduledTo, 'YYYY-MM-DD-HH:mm').valueOf() <= toDate)

  }
)
