import React, { FC } from 'react';
import './index.css';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';

const UPDATE_CATEGORY = gql`
  mutation updateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
    }
  }
`;

const CREATE_CATEGORY = gql`
  mutation createCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AddOrEditCategory: FC<any> = ({ data, close }) => {
  const [updateReq] = useMutation(UPDATE_CATEGORY, { errorPolicy: 'all' });
  const [createReq] = useMutation(CREATE_CATEGORY, { errorPolicy: 'all' });
  const isUpdate = data.id !== undefined;
  const { register, handleSubmit } = useForm();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    const input = {
      id: data.id,
      ...(isUpdate && {
        isDeleted:
          values.isDeleted === 'true' || values.isDeleted === true
            ? true
            : false,
      }),
      note: values.note,
      imagePath: values.imagePath,
      ...(!isUpdate && {
        name: values.name,
      }),
    };
    if (isUpdate) {
      const result = await updateReq({
        variables: {
          input,
        },
      });
      if (
        result.data &&
        result.data.updateCategory &&
        result.data.updateCategory.id
      ) {
        console.log('result', result);
        close();
      }
    } else {
      const result = await createReq({
        variables: {
          input,
        },
      });
      if (
        result.data &&
        result.data.createCategory &&
        result.data.createCategory.id
      ) {
        console.log('result', result);
        close();
      }
    }
  };

  return (
    <section>
      <div className="container">
        <div className="contactinfo">
          <div>
            <span style={{ color: '#fff' }}>
              {isUpdate ? 'Edit Category:' : 'Create Category:'}
            </span>
            <h2> {data.name}</h2>

            <div className="slots-container"></div>
          </div>
        </div>
        <div className="contactForm">
          <div className="formBox">
            <div className="inputBox w50  ">
              <input
                type="text"
                name="name"
                ref={register({ required: true, maxLength: 31 })}
                defaultValue={data.name}
                disabled={isUpdate}
                maxLength={30}
              />

              <span>Name</span>
            </div>

            <div className="inputBox w50  ">
              <input
                type="text"
                name="note"
                ref={register({ required: false, maxLength: 31 })}
                defaultValue={data.note}
                maxLength={30}
              />

              <span>Note</span>
            </div>

            <div className="inputBox w50  ">
              <label>Image Path</label>
              <div className="select-dropdown">
                <select
                  name="imagePath"
                  ref={register({ required: true })}
                  defaultValue={data.imagePath}
                >
                  <option value="assets/images/chicken.png">Chicken</option>
                  <option value="assets/images/crab.png">Crab</option>
                  <option value="assets/images/eggs.png">Eggs</option>
                  <option value="assets/images/clown-fish.png">Fish</option>
                  <option value="assets/images/sheep.png">Sheep/goat</option>
                  <option value="assets/images/pizza.png">Pizza</option>
                  <option value="assets/images/rice-bowl.png">Rice</option>
                  <option value="assets/images/shrimp.png">Shrimp</option>
                  <option value="assets/images/milk.png">Milk</option>
                  <option value="assets/images/dry-fruits.png">Dry Fruits</option>
                  <option value="assets/images/spices.png">Spices</option>
                  <option value="assets/images/fruits.png">Fruits</option>
                  <option value="assets/images/pickle.png">Pickle</option>
                  <option value="assets/images/veggies.png">Veggies</option>
                </select>
              </div>
            </div>

            {isUpdate && (
              <div className="inputBox w100  ">
                <input
                  type="checkbox"
                  placeholder="Delete this"
                  name="isDeleted"
                  className="styled-checkbox"
                  ref={register}
                  id="styled-checkbox-2"
                  defaultChecked={data.isDeleted}
                />
                <label htmlFor="styled-checkbox-2">
                  {data.isDeleted ? 'Enable' : 'Delete'}
                </label>
              </div>
            )}

            <div
              className="inputBox w100  "
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              {/* <input type="submit" value="save" /> */}
              <button onClick={handleSubmit(onSubmit)}>save</button>
              <button onClick={close}>close</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddOrEditCategory;
