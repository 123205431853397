import React from 'react';
import styled from 'styled-components';
import { useAuthState } from '../../hooks/useAuth';
const Container = styled.div`
  margin-top: 5rem;
`;

const ProfileImg = styled.img`
  height: 5rem;
`;
const ProfileName = styled.h1`
  font-size: 1rem;
  font-weight: 300;
  color: ${({ theme }) => theme.textColor};
`;

const ProfileImage = () => {
  const { profile } = useAuthState();

  return <img src={profile?.avatar} className="mr-2" alt="face" />;
};

export default ProfileImage;
