import React, { FC, useState } from 'react';
import './index.css';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { Multiselect } from 'multiselect-react-dropdown';

const UPDATE_SUB_CATEGORY = gql`
  mutation updateSubCategory($input: UpdateSubCategoryInput!) {
    updateSubCategory(input: $input) {
      id
    }
  }
`;

const CREATE_SUB_CATEGORY = gql`
  mutation createSubCategory($input: CreateSubCategoryInput!) {
    createSubCategory(input: $input) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AddOrEditSubCategory: FC<any> = ({
  data,
  close,
  serviceableAreasData,
}) => {
  const [updateReq] = useMutation(UPDATE_SUB_CATEGORY, { errorPolicy: 'all' });
  const [createReq] = useMutation(CREATE_SUB_CATEGORY, { errorPolicy: 'all' });
  const [indexes, setIndexes] = useState<Array<number>>(
    data.availableTimings
      ? data.availableTimings.map(
          (_: { from: string; to: string }, i: number) => i,
        )
      : [],
  );

  const [selectedValues, setSelectedValues] = useState((serviceableAreasData || []).filter(e => new Set(data?.serviceableAreaIds || []).has(e.id)));
  const [counter, setCounter] = useState<number>(
    data.availableTimings?.length > 0 ? data.availableTimings.length + 1 : 0,
  );
  const isUpdate = data.id !== undefined;

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      availableTimings: data.availableTimings,
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    if (isUpdate) {
      const result = await updateReq({
        variables: {
          input: {
            categoryId: data.categoryId,
            id: data.id,
            availableTimings: (values.availableTimings || [])
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .filter((entry: any) => entry !== undefined)
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .map((entry: any) => ({ from: entry.from, to: entry.to })),
            isDeleted: values.isDeleted,
            orderReserveTime: +values.orderReserveTime || 0,
            serviceableAreaIds: selectedValues.map(e => e.id),
          },
        },
      });
      if (
        result.data &&
        result.data.updateSubCategory &&
        result.data.updateSubCategory.id
      ) {
        console.log('result', result);
        close();
      }
    } else {
      const result = await createReq({
        variables: {
          input: {
            categoryId: data.categoryId,
            availableTimings: (values.availableTimings || [])
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .filter((entry: any) => entry !== undefined)
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .map((entry: any) => ({ from: entry.from, to: entry.to })),
            name: values.name,
            orderReserveTime: +values.orderReserveTime || 0,
            serviceableAreaIds: selectedValues.map(e => e.id),
          },
        },
      });
      if (
        result.data &&
        result.data.createSubCategory &&
        result.data.createSubCategory.id
      ) {
        console.log('result', result);
        close();
      }
    }
  };

  const addFriend = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setIndexes((prevIndexes: any) => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
  };

  const removeFriend = (index: number) => () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setIndexes((prevIndexes: any) => [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...prevIndexes.filter((item: any) => item !== index),
    ]);
    setCounter(prevCounter => prevCounter - 1);
  };

  const clearFriends = () => {
    setIndexes([]);
  };
  const onSelectArea = (selectedList, selectedItem) => {
    setSelectedValues(S => [...S, selectedItem]);
  };

  const onRemoveArea = (selectedList, removedItem) => {
    setSelectedValues(S => S.filter(e => e.id !== removedItem.id));
  };

  return (
    <section>
      <div className="container">
        <div className="contactinfo">
          <div>
            <span style={{ color: '#fff' }}>
              {' '}
              {isUpdate ? 'Edit Sub-Category:' : 'Create Sub-Category:'}
            </span>
            <h2> {data.name}</h2>

            <div className="slots-container">
              <h6>Available timings</h6>
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                indexes.map((index: any) => {
                  const fieldName = `availableTimings[${index}]`;
                  const fldErrors = errors?.availableTimings
                    ? errors?.availableTimings[index]
                    : {};

                  return (
                    <fieldset name={fieldName} key={fieldName} className="slot">
                      <label>
                        From ({fldErrors?.from?.message}
                        )
                        <input
                          type="text"
                          name={`${fieldName}.from`}
                          ref={register({
                            pattern: {
                              value: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
                              message: 'Provide in HH:mm',
                            },
                          })}
                        />
                      </label>

                      <label>
                        To(
                        {fldErrors?.to?.message}
                        )
                        <input
                          type="text"
                          name={`${fieldName}.to`}
                          ref={register}
                        />
                      </label>
                      <button type="button" onClick={removeFriend(index)}>
                        Remove
                      </button>
                    </fieldset>
                  );
                })
              }

              <div
                className="inputBox w100  "
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <button type="button" onClick={addFriend}>
                  Add Slot
                </button>
                {indexes.length > 0 && (
                  <button type="button" onClick={clearFriends}>
                    Clear Slots
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="contactForm">
          <div className="formBox">
            <div className="inputBox w100  ">
              <input
                type="text"
                name="name"
                ref={register({ required: true })}
                defaultValue={data.name}
                disabled={isUpdate}
              />

              <span>Name</span>
            </div>

            <div className="inputBox w50  ">
              <input
                type="text"
                name="imagePath"
                style={{ visibility: 'hidden' }}
              />
            </div>

            <div className="inputBox w50  ">
              <input
                type="text"
                name="orderReserveTime"
                ref={register({ required: true })}
                defaultValue={data.orderReserveTime}
              />

              <span>Order buffer time (in minutes)</span>
            </div>

            <Multiselect
              options={serviceableAreasData}
              displayValue="name"
              selectedValues={selectedValues}
              onSelect={onSelectArea} // Function will trigger on select event
              onRemove={onRemoveArea}
            />

            {isUpdate && (
              <div className="inputBox w100  ">
                <input
                  type="checkbox"
                  placeholder="Delete this"
                  name="isDeleted"
                  className="styled-checkbox"
                  ref={register}
                  id="styled-checkbox-2"
                  defaultChecked={data.isDeleted}
                />
                <label htmlFor="styled-checkbox-2">
                  {data.isDeleted ? 'Enable' : 'Delete'}
                </label>
              </div>
            )}
            <div
              className="inputBox w100  "
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              {/* <input type="submit" value="save" /> */}
              <button onClick={handleSubmit(onSubmit)}>save</button>
              <button onClick={close}>close</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddOrEditSubCategory;
