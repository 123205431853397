import React, { FC, useState, useEffect, Fragment, useCallback } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import Order from '../order';
import Modal from '../../components/Modal';
import AddOrEditCategory from '../add-or-edit-category';
import { Badge, ProgressBar } from 'react-bootstrap';
import dayjs from 'dayjs';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import './index.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  addCurrentOrders,
  setLoading,
  selectDisplayOrders,
  updateOrder,
  IOrder,
  addNewOrders,
} from '../../store/ordersReducer';
import { RootState } from '../../store/rootReducer';
import { set } from 'js-cookie';
import Sockette from 'sockette';
import { alertService } from '../../alert.service';
import { WS_URL } from '../../constants';
let ws = null;

const FETCH_ORDERS = gql`
  query getOrdersByDateRange($input: OrderByDateRangeFilterInput!) {
    ordersByDateRange(input: $input) {
      items {
        id
        address {
          address
          city
          houseNumber
          id
          landmark
          latitude
          longitude
          name
          postalCode
          state
          streetAddress
        }
        product {
          grossWt
          imageUrl
          name
          netWt
          pricePerUnit
          quantity
        }
        selectedTimeSlot
        orderFrequency
        orderStatus
        paymentStatus
        quantity
        totalPrice
        invoiceId
        createdAt
        scheduledTo
        # startFrom
        # tillDate
        customerMobile
        customerName
        userId
        customerInvoiceID
        customerOrderID
      }
      cursor
    }
  }
`;

const CANCEL_ORDER = gql`
  mutation cancelOrder(
    $invoiceId: String!
    $orderId: String!
    $userId: String
  ) {
    cancelOrder(invoiceId: $invoiceId, orderId: $orderId, userId: $userId) {
      id
      address {
        address
        city
        houseNumber
        id
        landmark
        latitude
        longitude
        name
        postalCode
        state
        streetAddress
      }
      product {
        grossWt
        imageUrl
        name
        netWt
        pricePerUnit
        quantity
      }
      selectedTimeSlot
      orderFrequency
      orderStatus
      paymentStatus
      quantity
      totalPrice
      invoiceId
      createdAt
      scheduledTo
      # startFrom
      # tillDate
    }
  }
`;

const UPDATE_ORDER_STATUS = gql`
  mutation updateOrderStatus(
    $invoiceId: String!
    $orderId: String!
    $orderStatus: OrderStatus!
    $userId: String
  ) {
    updateOrderStatus(
      invoiceId: $invoiceId
      orderId: $orderId
      orderStatus: $orderStatus
      userId: $userId
    ) {
      id
      address {
        address
        city
        houseNumber
        id
        landmark
        latitude
        longitude
        name
        postalCode
        state
        streetAddress
      }
      product {
        grossWt
        imageUrl
        name
        netWt
        pricePerUnit
        quantity
      }
      selectedTimeSlot
      orderFrequency
      orderStatus
      paymentStatus
      quantity
      totalPrice
      invoiceId
      createdAt
      scheduledTo
      # startFrom
      # tillDate
      customerMobile
      customerName
      userId
    }
  }
`;

export enum OrderStatus {
  INITIATED = 'INITIATED',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  SCHEDULED = 'SCHEDULED',
  DELIVERY_STARTED = 'DELIVERY_STARTED',
}

const Orders: FC = () => {
  const dispatch = useDispatch();
  const ordersState = useSelector((state: RootState) => state.orders);

  const startFrom = dayjs().hour(0).minute(0).second(0);
  const endAt = dayjs().hour(24).minute(0).second(0);

  const [startDate, setStartDate] = useState(
    new Date(startFrom.format('YYYY-MM-DD HH:mm')),
  );
  const [endDate, setEndDate] = useState(
    new Date(endAt.format('YYYY-MM-DD HH:mm')),
  );

  const displayOrders = useSelector(
    selectDisplayOrders(
      dayjs(startDate).startOf('day').valueOf(),
      dayjs(endDate).endOf('day').valueOf(),
    ),
  );

  const [cancelOrderReq] = useMutation(CANCEL_ORDER, { errorPolicy: 'all' });
  const [updateOrderStatusReq] = useMutation(UPDATE_ORDER_STATUS, {
    errorPolicy: 'all',
  });

  // TODO for now we will do load more (infinite pagination)
  // later on we will change this to prevCursor and nextCursor based pagination
  // const [nextCursor, setNextCursor] = useState<string>();
  // const [cursor, setCursor] = useState<string>();

  const [orderList, setOrderList] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (Object.keys(ordersState?.items).length === 0) {
      fetchOrders();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (ordersState?.cursor) {
        fetchOrders(ordersState?.cursor);
      }
    }, 1000);
  }, [ordersState?.cursor]);

  const onMessageReceied = event => {
    const newOrders = JSON.parse(event.data);
    dispatch(addNewOrders({ items: newOrders }));
    alertService.infoKeep(`Received ${newOrders.length} orders!`);
  };

  useEffect(() => {
    // if (props.authData)
    ws = new Sockette(WS_URL, {
      timeout: 5e3,
      maxAttempts: 1,
      onopen: e => console.log('connected:', e),
      onmessage: e => onMessageReceied(e),
      onreconnect: e => console.log('Reconnecting...', e),
      onmaximum: e => console.log('Stop Attempting!', e),
      onclose: e => console.log('Closed!', e),
      onerror: e => console.log('Error:', e),
    });
    return function cleanup() {
      ws && ws.close();
      ws = null;
    };
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [requestOrders, { loading, error, data }] = useLazyQuery(FETCH_ORDERS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      if (data.ordersByDateRange) {
        dispatch(
          addCurrentOrders({
            items:
              data.ordersByDateRange.items &&
              data.ordersByDateRange.items.length > 0
                ? data.ordersByDateRange.items
                : [],
            cursor: data.ordersByDateRange.cursor
              ? data.ordersByDateRange.cursor
              : ordersState?.cursor,
          }),
        );

        // setNextCursor(data.ordersByDateRange.cursor)
        // if () {
        //   setOrderList(O => [...O, ...data.ordersByDateRange.items])
        // }
      }
    }
  }, [data]);

  // const onLoadMore = () => {
  //   if (nextCursor) {
  //     setCursor(nextCursor)
  //   }
  // }

  const fetchOrders = useCallback(
    (cursor?: string) => {
      dispatch(setLoading('loading'));
      requestOrders({
        variables: {
          input: {
            startFrom: startFrom, // '2021-01-15 19:00',
            endAt: endAt, // '2021-01-15 19:00',
            limit: 10,
            cursor: cursor ? cursor : null,
          },
        },
      });
    },
    [startDate, endDate, requestOrders],
  );

  // useEffect(() => {
  //   if (startDate) {
  //     fetchOrders();
  //   }
  // }, [fetchOrders]);

  const updateLatestOrder = useCallback(() => {
      fetchOrders();
  }, [fetchOrders]);

  const handleChange = (date: Date) => {
    setStartDate(new Date(dayjs(date).format('YYYY-MM-DD HH:mm')));
  };
  const handleEndDateChange = (date: Date) => {
    setEndDate(new Date(dayjs(date).format('YYYY-MM-DD HH:mm')));
  };
  const [selectedOrderId, setSelectedOrderId] = useState<string>();

  const handleSelect = (id: string) => {
    setSelectedOrderId(id);
  };

  const cancelOrderClick = async (
    invoiceId: string,
    orderId: string,
    userId: string,
    oldOrder,
  ) => {
    const res = await cancelOrderReq({
      variables: {
        invoiceId,
        orderId,
        userId,
      },
    });
    if (res.data.cancelOrder && res.data.cancelOrder.id) {
      dispatch(
        updateOrder({
          orderId,
          updatedData: { ...oldOrder, ...res.data.cancelOrder } as IOrder,
        }),
      );
    }
  };

  const acceptOrderClick = async (
    invoiceId: string,
    orderId: string,
    userId: string,
  ) => {
    const res = await updateOrderStatusReq({
      variables: {
        userId,
        invoiceId,
        orderId,
        orderStatus: OrderStatus.CONFIRMED,
      },
    });
    if (res.data.updateOrderStatus && res.data.updateOrderStatus.id) {
      dispatch(
        updateOrder({
          orderId,
          updatedData: res.data.updateOrderStatus as IOrder,
        }),
      );
    }
  };

  const deliveryStartedOrderClick = async (
    invoiceId: string,
    orderId: string,
    userId: string,
  ) => {
    const res = await updateOrderStatusReq({
      variables: {
        userId,
        invoiceId,
        orderId,
        orderStatus: OrderStatus.DELIVERY_STARTED,
      },
    });
    if (res.data.updateOrderStatus && res.data.updateOrderStatus.id) {
      dispatch(
        updateOrder({
          orderId,
          updatedData: res.data.updateOrderStatus as IOrder,
        }),
      );
    }
  };

  const deliveredOrderClick = async (
    invoiceId: string,
    orderId: string,
    userId: string,
  ) => {
    const res = await updateOrderStatusReq({
      variables: {
        userId,
        invoiceId,
        orderId,
        orderStatus: OrderStatus.COMPLETED,
      },
    });
    if (res.data.updateOrderStatus && res.data.updateOrderStatus.id) {
      dispatch(
        updateOrder({
          orderId,
          updatedData: res.data.updateOrderStatus as IOrder,
        }),
      );
    }
  };
  if (error) return <p>Error :(</p>;

  return (
    <>
      <Modal open={modalIsOpen} closeModal={closeModal}>
        <AddOrEditCategory data={{}} close={closeModal} />
      </Modal>

      <div>
        <div className="page-header">
          <h3 className="page-title"> Orders </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              {/* <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Tables</a></li> */}
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row ">
                  <div className="col-lg-8">
                    <h4 className="card-title">
                      Scheduled orders{' '}
                      {ordersState.isLoading
                        ? 'fetching some orders list...'
                        : ''}
                    </h4>
                  </div>
                  <div className="col-lg-4 text-right">
                    <button
                      className="btn btn-dark btn-fw btn-sm"
                      onClick={updateLatestOrder}
                    >
                      Get latest orders
                    </button>
                  </div>
                </div>
                <div className="card-description">
                  <div className="row">
                    <div className="col-md-5">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">From</label>
                        <div className="col-sm-9">
                          <DatePicker
                            className="form-control w-100"
                            selected={startDate}
                            onChange={handleChange}
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">to</label>
                        <div className="col-sm-9">
                          <DatePicker
                            className="form-control w-100"
                            selected={endDate}
                            onChange={handleEndDateChange}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  The orders will be displayed from{' '}
                  <code>{startFrom.format('YYYY-MM-DD HH:mm')}</code> to
                  <code>{endAt.format('YYYY-MM-DD HH:mm')}</code>
                </div>

                {
                  <div className="table-responsive">
                    <table className="table orders-table">
                      <thead>
                        <tr>
                          <th> Frequency </th>
                          <th> Invoice ID </th>
                          <th> Status </th>
                          <th> Amount </th>
                          <th> Created At </th>
                          <th> Scheduled to </th>
                        </tr>
                      </thead>
                      <Accordion
                        as={'tbody'}
                        defaultActiveKey={
                          displayOrders?.length > 0 ? displayOrders[0].id : '0'
                        }
                        onSelect={handleSelect}
                      >
                        {(displayOrders || []).map((order: any) => {
                          return (
                            <Fragment key={order.id}>
                              <Accordion.Toggle
                                as={'tr'}
                                eventKey={order.id}
                                className={
                                  selectedOrderId === order.id
                                    ? 'selected-order-row'
                                    : ''
                                }
                              >
                                <td className="py-1 order-frequency">
                                  <Badge
                                    variant={
                                      order.orderFrequency === 'DAILY'
                                        ? 'dark'
                                        : 'info'
                                    }
                                  >
                                    {order.orderFrequency}
                                  </Badge>
                                </td>
                                <td> {order.customerInvoiceID} </td>
                                <td>{order.orderStatus}</td>
                                <td>
                                  <i className="mdi mdi-currency-inr"></i>
                                  {order.totalPrice}{' '}
                                </td>
                                <td>
                                {' '}
                                  {dayjs(order.createdAt).format(
                                    'DD MMM, YYYY HH:mm',
                                  )}{' '}                                </td>
                                <td>
                                  {' '}
                                  {dayjs(
                                    order.scheduledTo,
                                    'YYYY-MM-DD HH:mm',
                                  ).format('DD MMM, YYYY HH:mm')}
                                </td>
                              </Accordion.Toggle>
                              <tr
                                className={`${
                                  selectedOrderId === order.id
                                    ? 'selected-order-row'
                                    : ''
                                } order-details`}
                              >
                                <td colSpan={6}>
                                  <Accordion.Collapse eventKey={order.id}>
                                    <div className="col-md-12 grid-margin mt-4 order-delivery-details">
                                      <div className="card pt-4">
                                        <div className="card-body">
                                          <div className="row">
                                            <div className="col-md-6 grid-margin">
                                              <div className="row">
                                                <div className="">
                                                  <div className="card-text">
                                                    <img
                                                      src={
                                                        order.product.imageUrl
                                                      }
                                                      alt="product image"
                                                      className="product-image"
                                                    />

                                                    <h4 className="card-title">
                                                      <span className="text-dark">
                                                        Product:
                                                      </span>{' '}
                                                      {order.product?.name}
                                                    </h4>
                                                    <ul className="list-arrow">
                                                      <li>
                                                        <span className="text-dark">
                                                          Price per unit:
                                                        </span>
                                                        {order.product
                                                          ?.pricePerUnit ||
                                                          'N/A'}
                                                      </li>
                                                      <li>
                                                        <span className="text-dark">
                                                          Net weight:
                                                        </span>
                                                        {order.product?.netWt ||
                                                          'N/A'}
                                                      </li>

                                                      <li>
                                                        <span className="text-dark">
                                                          Gross Weight:
                                                        </span>
                                                        {order.product
                                                          ?.grossWt || 'N/A'}
                                                      </li>

                                                      <li>
                                                        <span className="text-dark">
                                                          Purchased quantity:
                                                        </span>
                                                        {order.quantity ||
                                                          'N/A'}
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-md-4">
                                                  <p className="text-dark">
                                                    Payment mode
                                                  </p>
                                                  <p className="text-dark">
                                                    Payment Status
                                                  </p>
                                                  <p className="text-dark">
                                                    Order Status
                                                  </p>
                                                  <p className="text-dark">
                                                    Order Frequency
                                                  </p>

                                                  <p className="text-dark">
                                                    Customer Order ID
                                                  </p>
                                                </div>
                                                <div className="col-md-8">
                                                  <p className="text-dark">
                                                    <label className="badge badge-primary">
                                                      COD
                                                    </label>
                                                  </p>

                                                  <p className="text-dark">
                                                    <label className="badge badge-primary">
                                                      {order.orderStatus}
                                                    </label>
                                                  </p>
                                                  <p className="text-dark">
                                                    <label className="badge badge-primary">
                                                      {order.paymentStatus}
                                                    </label>
                                                  </p>
                                                  <p className="text-dark">
                                                    <label className="badge badge-primary">
                                                      {order.orderFrequency}
                                                    </label>
                                                  </p>
                                                  <p className="text-dark">
                                                    <label className="badge badge-gradient-success">
                                                      {order.customerOrderID}
                                                    </label>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-md-6 grid-margin">
                                              <div className="row">
                                                <p className="card-description">
                                                  {' '}
                                                  Total Price:{' '}
                                                </p>

                                                <h4 className="card-title">
                                                  <i className="mdi mdi-currency-inr"></i>
                                                  {order.totalPrice}
                                                </h4>
                                              </div>

                                              <div className="row">
                                                <div className="col-md-4 d-flex align-items-center">
                                                  Ordered Date
                                                </div>

                                                <div className="col-md-8 d-flex align-items-center">
                                                  <div className="d-flex flex-row align-items-center">
                                                    <i className="mdi mdi-calendar-check text-warning"></i>
                                                    <p className="mb-0 ml-1">
                                                      {dayjs(
                                                        order.createdAt,
                                                      ).format(
                                                        'DD MMM, YYYY HH:mm',
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-md-4 d-flex align-items-center">
                                                  Scheduled Date
                                                </div>
                                                <div className="col-md-8 d-flex align-items-center">
                                                  <div className="d-flex flex-row align-items-center">
                                                    <i className="mdi mdi-calendar-clock text-success"></i>
                                                    <p className="mb-0 ml-1">
                                                      {dayjs(
                                                        order.scheduledTo,
                                                        'YYYY-MM-DD HH:mm',
                                                      ).format(
                                                        'DD MMM, YYYY HH:mm',
                                                      )}{' '}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-md-4">
                                                  <address className="">
                                                    <p className="">
                                                      Customer Name
                                                    </p>
                                                    <p className="">
                                                      Customer Phone
                                                    </p>
                                                  </address>
                                                </div>
                                                <div className="col-md-8">
                                                  <address className="">
                                                    <p className="mb-2 font-weight-bold">
                                                      {order.customerName}
                                                    </p>
                                                    <p className="font-weight-bold">
                                                      {' '}
                                                      {
                                                        order.customerMobile
                                                      }{' '}
                                                    </p>
                                                  </address>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-md-12">
                                                  <h4 className="card-title">
                                                    Address
                                                  </h4>
                                                  <address>
                                                    <p className="font-weight-bold">
                                                      {' '}
                                                      {order.address.name}{' '}
                                                    </p>
                                                    <p className="">
                                                      {order.address.landmark}
                                                    </p>
                                                    <p>
                                                      {' '}
                                                      {
                                                        order.address
                                                          .houseNumber
                                                      }
                                                    </p>
                                                    <p>
                                                      {' '}
                                                      {
                                                        order.address
                                                          .streetAddress
                                                      }{' '}
                                                    </p>
                                                    <p>
                                                      {' '}
                                                      {order.address.address},{' '}
                                                      {order.address.postalCode}
                                                      , {order.address.city}{' '}
                                                    </p>
                                                  </address>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-md-12 d-flex align-items-center justify-content-center py-4">
                                              {new Set([
                                                OrderStatus.DELIVERY_STARTED,
                                              ]).has(order.orderStatus) &&
                                                !new Set([
                                                  OrderStatus.CONFIRMED,
                                                  OrderStatus.CANCELLED,
                                                  OrderStatus.COMPLETED,
                                                ]).has(order.orderStatus) && (
                                                  <button
                                                    type="button"
                                                    className="btn btn-success btn-fw"
                                                    onClick={() =>
                                                      deliveredOrderClick(
                                                        order.invoiceId,
                                                        order.id,
                                                        order.userId,
                                                      )
                                                    }
                                                  >
                                                    Delivered
                                                  </button>
                                                )}
                                              {new Set([
                                                OrderStatus.INITIATED,
                                              ]).has(order.orderStatus) &&
                                                !new Set([
                                                  OrderStatus.COMPLETED,
                                                  OrderStatus.DELIVERY_STARTED,
                                                ]).has(order.orderStatus) && (
                                                  <button
                                                    type="button"
                                                    className="btn btn-danger btn-fw"
                                                    onClick={() =>
                                                      cancelOrderClick(
                                                        order.invoiceId,
                                                        order.id,
                                                        order.userId,
                                                        order,
                                                      )
                                                    }
                                                  >
                                                    Cancel
                                                  </button>
                                                )}
                                              {new Set([
                                                OrderStatus.CONFIRMED,
                                              ]).has(order.orderStatus) &&
                                                !new Set([
                                                  OrderStatus.CANCELLED,
                                                  OrderStatus.COMPLETED,
                                                  OrderStatus.DELIVERY_STARTED,
                                                ]).has(order.orderStatus) && (
                                                  <button
                                                    type="button"
                                                    className="btn btn-warning btn-fw"
                                                    onClick={() =>
                                                      deliveryStartedOrderClick(
                                                        order.invoiceId,
                                                        order.id,
                                                        order.userId,
                                                      )
                                                    }
                                                  >
                                                    Delivery Started
                                                  </button>
                                                )}
                                              {new Set([
                                                OrderStatus.INITIATED,
                                              ]).has(order.orderStatus) &&
                                                !new Set([
                                                  OrderStatus.CONFIRMED,
                                                  OrderStatus.CANCELLED,
                                                  OrderStatus.COMPLETED,
                                                  OrderStatus.DELIVERY_STARTED,
                                                ]).has(order.orderStatus) && (
                                                  <button
                                                    type="button"
                                                    className="btn btn-dark btn-fw"
                                                    onClick={() =>
                                                      acceptOrderClick(
                                                        order.invoiceId,
                                                        order.id,
                                                        order.userId,
                                                      )
                                                    }
                                                  >
                                                    Confirm order
                                                  </button>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Accordion.Collapse>
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })}
                      </Accordion>
                    </table>

                    {/* {nextCursor && <button
                      className="btn btn-outline-dark btn-sm"
                      onClick={onLoadMore}
                    >
                      Load More
                    </button>} */}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
