import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { GAPI_KEY } from '../constants';
import Login from '../user-pages/Login';
import { Form } from 'react-bootstrap';

import Logo from './../logo.svg';
import Logo2 from './../logo2.svg';

const key: string = GAPI_KEY;

function UnauthenticatedContent({
  markAsUserLoggedIn,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  markAsUserLoggedIn: any;
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseGoogle = (response: any) => {
    markAsUserLoggedIn(response);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onAutoLoadFinished = (e: any) => {
    console.log('onAutoLoadFinished', e);
  };
  return (
      <GoogleLogin
        isSignedIn={true}
        clientId={key}
        buttonText="Login"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
        onAutoLoadFinished={onAutoLoadFinished}
        className="google-btn"
      />
  );
}


function UnauthenticatedApp({
  markAsUserLoggedIn,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  markAsUserLoggedIn: any;
}) {
  return (
    <>
      {/* <div className="container-login100">
      </div> */}

    <div className="container-login100">
			<div className="wrap-login100">
				<div className="login100-pic">
          <img src={Logo2} className="card-img-absolute logo-2" alt="circle" />
				</div>

				<div className="login100-form validate-form">
					<span className="login100-form-title">
          {/* <img src={Logo} className="card-img-absolute" alt="circle" width="200"/> */}
					</span>
        <UnauthenticatedContent markAsUserLoggedIn={markAsUserLoggedIn} />

				</div>
			</div>
		</div>

    </>
  );
}

export default UnauthenticatedApp;
