import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from './shared/Spinner';


import SubCategories from './containers/subCategories';
import ServiceableAreas from './containers/serviceable-areas';
import Categories from './containers/categories';
import VariantProducts from './containers/variantProducts';
import Orders from './containers/orders';
import Banners from './containers/banners';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));



const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));


const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));



const Mdi = lazy(() => import('./icons/Mdi'));


const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register1 = lazy(() => import('./user-pages/Register'));
const Lockscreen = lazy(() => import('./user-pages/Lockscreen'));

const BlankPage = lazy(() => import('./general-pages/BlankPage'));




class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/categories/:categoryId/sub-categories/:subCategoryId/products">
            <VariantProducts />
          </Route>
          <Route path="/categories/:categoryId/sub-categories/">
            <SubCategories />
          </Route>
          <Route path="/categories">
            <Categories />
          </Route>

          <Route path="/service-areas">
            <ServiceableAreas />
          </Route>

          <Route path="/banners-and-deals">
            <Banners />
          </Route>

          <Route path="/orders/scheduled">
            <Orders />
          </Route>


          <Route exact path="/dashboard" component={Dashboard} />


          <Route path="/basic-ui/buttons" component={Buttons} />
          <Route path="/basic-ui/dropdowns" component={Dropdowns} />
          <Route path="/basic-ui/typography" component={Typography} />


          <Route path="/form-Elements/basic-elements" component={BasicElements} />

          <Route path="/tables/basic-table" component={BasicTable} />


          <Route path="/icons/mdi" component={Mdi} />


          <Route path="/charts/chart-js" component={ChartJs} />


          <Route path="/user-pages/login-1" component={Login} />
          <Route path="/user-pages/register-1" component={Register1} />
          <Route path="/user-pages/lockscreen" component={Lockscreen} />

          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />

          <Route path="/general-pages/blank-page" component={BlankPage} />


          {/* <Route path="/">
            <Dashboard />
          </Route> */}

          <Redirect to="/categories" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
