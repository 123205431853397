import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useRouteMatch, Link } from 'react-router-dom';
import Modal from '../../components/Modal';
import AddOrEditSubCategory from '../add-or-edit-sub-category';
import { Trans } from 'react-i18next';


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SubCategory: FC<any> = ({ data, serviceableAreasData }) => {
  const { name, isDeleted, id,orderReserveTime,serviceableAreaIds } = data;
  const match = useRouteMatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState();

  function openModal() {
    setIsOpen(true);
    setModalData(data);
  }

  function closeModal() {
    setIsOpen(false);
    setModalData(undefined);
  }

  return (
    <>
      <Modal open={modalIsOpen} closeModal={closeModal}>
        <AddOrEditSubCategory serviceableAreasData={serviceableAreasData} data={modalData} close={closeModal} />
      </Modal>

      <tr>
        <td>{name}</td>
        <td>{id}</td>
        <td>
          <label className={`badge badge-${isDeleted ? 'danger' : 'success'}`}>
            {isDeleted ? 'Deleted' : 'Active'}
          </label>
        </td>
        <td>{orderReserveTime || '0'}</td>
        <td>{serviceableAreasData.filter(e => new Set(serviceableAreaIds).has(e.id)).map(e => e.name).join(',')}</td>
        <td>
          <Link
            className="btn btn-outline-dark btn-fw btn-sm"
            to={`${match.url}/${id}/products`}
          >
            <span className="menu-title">
              <Trans>View</Trans>
            </span>
          </Link>
          <button className="btn btn-dark btn-fw btn-sm" onClick={openModal}>
            edit
          </button>
        </td>
      </tr>
    </>
  );
};

export default SubCategory;
