import { gql, useMutation } from '@apollo/client';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import Switch from '../../components/Switch';
import AddOrEditProduct from '../add-or-edit-product';
import './index.scss'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(190, 190, 190, 0.22);
  cursor: pointer;
  background-color: ${({ theme }) => theme.primary};
  transition: all ease-in-out 300ms;

  &:hover {
    /* box-shadow: 0px 10px 8px -8px rgba(138, 153, 192, 0.6); */
    background-color: ${({ theme }) => theme.secondary};
  }
`;

const Text = styled.h1`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textColor};
  margin: 0;
`;

const Subtitle = styled(Text)`
  font-size: 0.6rem;
  color: #b2bfe1;
  margin-top: 2px;
`;

const Property = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
`;

const PropertyText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;
const Rent = styled(Text)`
  width: 10%;
`;

const PropertyStreet = styled(Text)`
  font-size: 1rem;
`;
const DepositWrapper = styled.div`
  width: 15%;
`;
const Status = styled.div`
  display: flex;
  align-items: center;
`;
const StatusIndicator = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 10px;
  background-color: ${props => props.color};
  margin-left: 1rem;
  position: absolute;
  right: 7rem;
`;

const ProductImg = styled.img`
  height: 5rem;
`;
const SET_AS_PROMOTIONAL = gql`
  mutation setAsPromotionalProduct($product: ID!, $variant: ID!) {
    setAsPromotionalProduct(product: $product, variant: $variant)
  }
`;

const UNSET_AS_PROMOTIONAL = gql`
  mutation unsetAsPromotionalProduct($product: ID!, $variant: ID!) {
    unsetAsPromotionalProduct(product: $product, variant: $variant)
  }
`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const VariantProduct: FC<any> = ({ data }) => {
  const [
    setAsPromotionalReq,
    { loading: setAsPromotionalLoading },
  ] = useMutation(SET_AS_PROMOTIONAL, { errorPolicy: 'all' });
  const [
    unsetAsPromotionalReq,
    { loading: unsetAsPromotionalLoading },
  ] = useMutation(UNSET_AS_PROMOTIONAL, { errorPolicy: 'all' });

  const {
    isDeleted,
    name,
    price,
    salePrice,
    imageUrl,
    netWt,
    grossWt,
    description,
    id,
    productId
  } = data;

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [isChecked, setIsChecked] = useState<boolean>(
    data.promotionalProduct || false,
  );

  function openModal() {
    setIsOpen(true);
    setModalData(data);
  }

  function closeModal() {
    setIsOpen(false);
    setModalData(undefined);
  }

  const _handlePromotionalProductChange = (value: boolean) => {
    setIsChecked(value);
    if(`${value}` === 'true') {
      setAsPromotionalReq({
        variables: {
          product:productId,
          variant:id

        }
      })
    } else {
      unsetAsPromotionalReq({
        variables: {
          product:productId,
          variant:id
        }
      })
    }
  };

  const loading = setAsPromotionalLoading || unsetAsPromotionalLoading;

  return (
    <>
      <Modal open={modalIsOpen} closeModal={closeModal}>
        <AddOrEditProduct data={modalData} close={closeModal} />
      </Modal>

        <tr>
      {loading && <td colSpan={9} className="update-loading">Updating...</td>}
      {!loading && (<>    <td>{name}</td>
          <td>
            {netWt} {grossWt ? '/' : ''} {grossWt}
          </td>
          <td>
            <ProductImg src={imageUrl} width={100} />
          </td>
          <td>{description}</td>
          <td>₹{price}</td>
          <td>₹{salePrice}</td>
          <td>
            <Switch
            id={id}
              title={''}
              isChecked={isChecked}
              onChange={_handlePromotionalProductChange}
            />
          </td>
          <td>
            <label
              className={`badge badge-${isDeleted ? 'danger' : 'success'}`}
            >
              {isDeleted ? 'Deleted' : 'Active'}
            </label>
          </td>
          <td>
            <button className="btn btn-dark btn-fw btn-sm" onClick={openModal}>
              edit
            </button>
          </td>
          </>
          )}
        </tr>
    </>
  );
};

export default VariantProduct;
