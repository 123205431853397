import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './styles/theme';
import { GlobalStyles } from './styles/global';
import { ThemeContext } from './context/themeContext';
import { useAuthState, AuthProvider } from './hooks/useAuth';
import AuthenticatedApp from './layouts/protected';
import Alert from './components/Alert';
import UnauthenticatedApp from './layouts/guest';
import './App.scss';
import { Provider } from 'react-redux';
import store, { persistor } from './store/store';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';

function Home() {
  const { markAsUserLoggedIn, token } = useAuthState();
  return token ? (
    <AuthenticatedApp />
  ) : (
    <UnauthenticatedApp markAsUserLoggedIn={markAsUserLoggedIn} />
  );
}

function App() {
  const context = useContext(ThemeContext);
  const { theme } = context;

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <>
        <Alert />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <GlobalStyles />
            <AuthProvider>
              <div>
                <Home />
              </div>
            </AuthProvider>
          </PersistGate>
        </Provider>
      </>
    </ThemeProvider>
  );
}

export default App;
