import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
  } from 'redux-persist'
  import storage from 'redux-persist/lib/storage'
  import rootReducer from './rootReducer'
  import localForage from 'localforage';


  localForage.config({
    driver      : localForage.INDEXEDDB, // Force WebSQL; same as using setDriver()
    name        : 'myApp',
    version     : 1.0,
    size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
    storeName   : 'keyvaluepairs', // Should be alphanumeric, with underscores.
    description : 'some description'
});

  const persistConfig = {
    key: 'root',
    version: 1,
    storage: localForage
  }
  
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  
  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
  })
  
export  const persistor = persistStore(store)

  


export type AppDispatch = typeof store.dispatch

export default store