import { useState } from 'react';
import './index.scss';

{
  /*
<div class="container">
  <div class="switch-box">
    <input id="default" class="switch-box-input" type="checkbox" />
    <label for="default" class="switch-box-slider"></label>
    <label for="default" class="switch-box-label">Default</label>
  </div>
  <div class="switch-box is-primary">
    <input id="primary" class="switch-box-input" type="checkbox" checked />
    <label for="primary" class="switch-box-slider"></label>
    <label for="primary" class="switch-box-label">Primary</label>
  </div>
  <div class="switch-box is-info">
    <input id="info" class="switch-box-input" type="checkbox" checked />
    <label for="info" class="switch-box-slider"></label>
    <label for="info" class="switch-box-label">Info</label>
  </div>
  <div class="switch-box is-success">
    <input id="success" class="switch-box-input" type="checkbox" checked />
    <label for="success" class="switch-box-slider"></label>
    <label for="success" class="switch-box-label">Success</label>
  </div>
  <div class="switch-box is-danger">
    <input id="danger" class="switch-box-input" type="checkbox" checked />
    <label for="danger" class="switch-box-slider"></label>
    <label for="danger" class="switch-box-label">Danger</label>
  </div>
  <div class="switch-box is-warning">
    <input id="warning" class="switch-box-input" type="checkbox" checked />
    <label for="warning" class="switch-box-slider"></label>
    <label for="warning" class="switch-box-label">Warning</label>
  </div>
  <div class="switch-box is-primary">
    <input id="disabled" class="switch-box-input" type="checkbox" checked disabled />
    <label for="disabled" class="switch-box-slider"></label>
    <label for="disabled" class="switch-box-label">Disabled</label>
  </div>
</div> */
}
const Switch = ({ id, isChecked, onChange, title }) => {
  const _handleChange = () => {
    onChange(!isChecked);
  };

  return (
    <div className="container">
      <div className="switch-box is-primary">
        <input
          id={id}
          className="switch-box-input"
          type="checkbox"
          checked={isChecked}
          onChange={_handleChange}
        />
        <label htmlFor={id} className="switch-box-slider"></label>
        <label htmlFor={id} className="switch-box-label">
          {title}
        </label>
      </div>
    </div>
  );
};

export default Switch;
