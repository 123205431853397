import React, { FC, useState, Component } from 'react';
import { gql, useQuery } from '@apollo/client';
import styled from 'styled-components';
import Category from '../category';
import Modal from '../../components/Modal';
import AddOrEditCategory from '../add-or-edit-category';
import { ProgressBar } from 'react-bootstrap';

const EXCHANGE_RATES = gql`
  query getCategories {
    categories(filters: {}) {
      id
      name
      isDeleted
      note
      imagePath
    }
  }
`;

const Categories: FC = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const { loading, error, data } = useQuery(EXCHANGE_RATES);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <>
      <Modal open={modalIsOpen} closeModal={closeModal}>
        <AddOrEditCategory data={{}} close={closeModal} />
      </Modal>

      <div>
        <div className="page-header">
          <h3 className="page-title"> Categories </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              {/* <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Tables</a></li>
          <li className="breadcrumb-item active" aria-current="page">Basic tables</li> */}
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row ">
                  <div className="col-lg-8">
                    <h4 className="card-title">Manage Categories</h4>
                  </div>
                  <div className="col-lg-4 text-right">
                    <button
                      className="btn btn-dark btn-fw btn-sm"
                      onClick={openModal}
                    >
                      Add
                    </button>
                  </div>
                </div>
                <p className="card-description">
                  {' '}
                  Click <code>edit</code> to manage category. Click{' '}
                  <code>view</code> to view sub-category
                </p>

                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Note</th>
                        <th>imagePath</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.categories.map((category: any) => (
                        <Category data={category} key={category.id} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
