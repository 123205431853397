import React, { useState, useEffect } from 'react';
import { alertService, ALERT_TYPE, IAlert } from '../../alert.service';
import './index.css';

const ALERT_TYPE_CLASS = {
  [ALERT_TYPE.SUCCESS]: 'success',
  [ALERT_TYPE.ERROR]: 'danger',
  [ALERT_TYPE.INFO]: 'info',
  [ALERT_TYPE.WARNING]: 'warning',
};

const Alert = () => {
  const [alerts, setAlerts] = useState<Array<IAlert>>([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const subscription = alertService.onAlert().subscribe((data: any) => {
      // TODO: fix typings for this
      setAlerts([...data]);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const removeAlert = (alert: IAlert) => {
    alertService.remove(alert);
  };

  if (!alerts.length) return null;
  return (
    <div className="notification-header">
      {alerts.map((alert, index) => (
        <div
          key={index}
          className={`message-wrapper ${
            ALERT_TYPE_CLASS[alert.type] && [ALERT_TYPE_CLASS[alert.type]]
          }`}
          onClick={() => removeAlert(alert)}
        >
          <span className="message">{alert.message}</span>
          <span className="close">&times;</span>
        </div>
      ))}
    </div>
  );
};

export default Alert;
