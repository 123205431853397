import React, { FC, useState } from 'react';
import './index.css';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import Upload from '../../components/Upload';

const UPDATE_PRODUCT = gql`
  mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation createProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AddOrEditProduct: FC<any> = ({ data, close }) => {
  const [updateReq] = useMutation(UPDATE_PRODUCT, { errorPolicy: 'all' });
  const [createReq] = useMutation(CREATE_PRODUCT, { errorPolicy: 'all' });
  const [imgUrl, setImgUrl] = useState(data.imagePath);
  const [indexes, setIndexes] = useState<Array<number>>(
    data.availableTimings
      ? data.availableTimings.map(
          (_: { from: string; to: string }, i: number) => i,
        )
      : [],
  );
  const [counter, setCounter] = useState<number>(
    data.availableTimings?.length > 0 ? data.availableTimings.length + 1 : 0,
  );
  const isUpdate = data.id !== undefined;

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      availableTimings: data.availableTimings,
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    if ((data.imageUrl || imgUrl || '').length === 0) {
      alert('upload image!');
      return;
    }
    if (isUpdate) {
      const result = await updateReq({
        variables: {
          input: {
            subCategoryId: data.productId,
            categoryId: data.categoryId,
            id: data.id,
            availableTimings: (values.availableTimings || [])
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .filter((entry: any) => entry !== undefined)
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .map((entry: any) => ({ from: entry.from, to: entry.to })),
            isDeleted: values.isDeleted,
            imageUrl: imgUrl,
            availableQuantity: +values.availableQuantity || 0,
            price: +values.price,
            salePrice: +values.salePrice,
            netWt: values.netWt,
            grossWt: values.grossWt,
            description: values.description,
          },
        },
      });
      if (
        result.data &&
        result.data.updateProduct &&
        result.data.updateProduct.id
      ) {
        console.log('result', result);
        close();
      }
    } else {
      const result = await createReq({
        variables: {
          input: {
            name: values.name,
            categoryId: data.categoryId,
            productId: data.productId,

            availableTimings: (values.availableTimings || [])
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .filter((entry: any) => entry !== undefined)
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .map((entry: any) => ({ from: entry.from, to: entry.to })),
            imageUrl: imgUrl || values.imageUrl,
            availableQuantity: +values.availableQuantity || 0,
            price: +values.price,
            salePrice: +values.salePrice,
            netWt: values.netWt,
            grossWt: values.grossWt,
            description: values.description,
          },
        },
      });
      if (
        result.data &&
        result.data.createProduct &&
        result.data.createProduct.id
      ) {
        console.log('result', result);
        close();
      }
    }
  };

  const addFriend = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setIndexes((prevIndexes: any) => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
  };

  const removeFriend = (index: number) => () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setIndexes((prevIndexes: any) => [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...prevIndexes.filter((item: any) => item !== index),
    ]);
    setCounter(prevCounter => prevCounter - 1);
  };

  const clearFriends = () => {
    setIndexes([]);
  };

  const onUploadSuccess = (url: string) => {
    if (url) {
      setImgUrl(url);
    }
  };

  return (
    <section>
      <div className="container">
        <div className="contactinfo">
          <div>
            <span style={{ color: '#fff' }}>
              {isUpdate ? 'Edit Product:' : 'Create Product:'}
            </span>
            <h2> {data.name}</h2>

            <Upload
              register={register}
              onUploadSuccess={onUploadSuccess}
              existingUrl={data.imageUrl}
            />
            <div className="slots-container">
              <h6>Available timings</h6>
              {indexes.map((index: number) => {
                const fieldName = `availableTimings[${index}]`;
                const fldErrors = errors?.availableTimings
                  ? errors?.availableTimings[index]
                  : {};
                return (
                  <fieldset name={fieldName} key={fieldName} className="slot">
                    <label>
                      From ({fldErrors?.from?.message}
                      )
                      <input
                        type="text"
                        name={`${fieldName}.from`}
                        ref={register({
                          pattern: {
                            value: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
                            message: 'Provide in HH:mm',
                          },
                        })}
                      />
                    </label>

                    <label>
                      To(
                      {fldErrors?.to?.message}
                      )
                      <input
                        type="text"
                        name={`${fieldName}.to`}
                        ref={register({
                          pattern: {
                            value: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
                            message: 'Provide in HH:mm',
                          },
                        })}
                      />
                    </label>
                    <button type="button" onClick={removeFriend(index)}>
                      Remove
                    </button>
                  </fieldset>
                );
              })}

              <div
                className="inputBox w100  "
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <button type="button" onClick={addFriend}>
                  Add Slot
                </button>
                {indexes.length > 0 && (
                  <button type="button" onClick={clearFriends}>
                    Clear Slots
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="contactForm">
          <div className="formBox">
            <div className="inputBox w50  ">
              <input
                type="text"
                name="name"
                ref={register({ required: true })}
                defaultValue={data.name}
                disabled={isUpdate}
              />

              <span>Name</span>
            </div>

            <div className="inputBox w50  ">
              <input
                type="text"
                name="salePrice"
                ref={register({ required: true })}
                defaultValue={data.salePrice}
              />

              <span>Sale Price</span>
            </div>
            <div className="inputBox w50  ">
              <input
                type="text"
                name="price"
                ref={register({ required: true })}
                defaultValue={data.price}
              />

              <span>Price</span>
            </div>

            <div className="inputBox w50  ">
              <input
                type="text"
                name="netWt"
                ref={register({ required: false })}
                defaultValue={data.netWt}
              />

              <span>Net weight</span>
            </div>

            <div className="inputBox w50  ">
              <input
                type="text"
                name="grossWt"
                ref={register}
                defaultValue={data.grossWt}
              />

              <span>Gross weight</span>
            </div>

            <div className="inputBox w50  ">
              <input
                type="text"
                name="availableQuantity"
                ref={register({})}
                defaultValue={data.availableQuantity}
              />

              <span>Available Quantity</span>
            </div>

            <div className="inputBox w100  ">
              <textarea
                name="description"
                ref={register}
                defaultValue={data.description}
              ></textarea>

              <span>Description</span>
            </div>
            {isUpdate && (
              <div className="inputBox w100  ">
                <input
                  type="checkbox"
                  placeholder="Delete this"
                  name="isDeleted"
                  className="styled-checkbox"
                  ref={register}
                  id="styled-checkbox-2"
                  defaultChecked={data.isDeleted}
                />
                <label htmlFor="styled-checkbox-2">
                  {data.isDeleted ? 'Enable' : 'Delete'}
                </label>
              </div>
            )}

            <div
              className="inputBox w100  "
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              {/* <input type="submit" value="save" /> */}
              <button onClick={handleSubmit(onSubmit)}>save</button>
              <button onClick={close}>close</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddOrEditProduct;
