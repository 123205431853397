import React from 'react';
import styled from 'styled-components';
import { useAuthState } from '../../hooks/useAuth';


const ProfileName = () => {
  const { profile } = useAuthState();

  return profile?.name;
};

export default ProfileName;
