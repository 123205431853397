import React, { FC, useEffect, useState, Component } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import styled from 'styled-components';
import SubCategory from '../subCategory';
import { useParams } from 'react-router-dom';
import Modal from '../../components/Modal';
import AddOrEditSubCategory from '../add-or-edit-sub-category';
import Category from '../category';
import { ProgressBar } from 'react-bootstrap';

const GET_SUB_CATEGORIES = gql`
  query getSubCategories($category: String) {
    products(category: $category, filters: {}) {
      id
      isDeleted
      name
      imageUrl
      availableTimings {
        from
        to
      }
      categoryId
      orderReserveTime
      serviceableAreaIds
    }
  }
`;

const GET_AREAS = gql`
  query getAreas {
    serviceableAreas {
      id
      name
      isDeleted
      latitude
      longitude
      allowedPinCodes
      allowedDisplacementInMeters
      description
    }
  }
`;

const SubCategories: FC = () => {
  const [getSubCats, res] = useLazyQuery(GET_SUB_CATEGORIES);
  const { loading, error, data: serviceableAreasData } = useQuery(GET_AREAS);

  const { categoryId } = useParams<{ categoryId: string }>();

  useEffect(() => {
    if (categoryId) {
      getSubCats({
        variables: {
          category: categoryId,
        },
      });
    }
  }, [categoryId, getSubCats]);

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  if (res.loading || !res.data) return <p>Loading...</p>;
  if (res.error) return <p>Error :(</p>;

    return (
      <>
      <Modal open={modalIsOpen} closeModal={closeModal}>
        <AddOrEditSubCategory data={{ categoryId }} close={closeModal} serviceableAreasData={serviceableAreasData?.serviceableAreas || []} />
      </Modal>

        <div>
          <div className="page-header">
            <h3 className="page-title"> Sub Categories </h3>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                {/* <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Tables</a></li>
            <li className="breadcrumb-item active" aria-current="page">Basic tables</li> */}
              </ol>
            </nav>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="row ">
                    <div className="col-lg-8">
                      <h4 className="card-title">Manage sub - Categories</h4>
                    </div>
                    <div className="col-lg-4 text-right">
                      <button
                        className="btn btn-dark btn-fw btn-sm"
                        onClick={openModal}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <p className="card-description">
                    {' '}
                    Click <code>edit</code> to manage sub-category. Click{' '}
                    <code>view</code> to view products
                  </p>

                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Note</th>
                          <th>Status</th>
                          <th>Buffer time (in minutes)</th>
                          <th>Areas</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {res.data.products.map((category: any) => (
                          <SubCategory serviceableAreasData={serviceableAreasData?.serviceableAreas || []} data={category} key={category.id} />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default SubCategories;
