import React, {
  useContext,
  useState,
  createContext,
  useCallback,
  useMemo,
  ReactNode,
} from 'react';
import { useCookies } from 'react-cookie';
import md5 from 'md5';

interface Profile {
  name: string;
  avatar: string;
}
interface AuthI {
  status: string;
  error: { message?: string };
  user?: { username?: string };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  markAsUserLoggedIn: any;
  token?: string;
  profile?: Profile;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logout: any;
}
const AuthContext = createContext<AuthI>({
  status: 'pending',
  error: { message: undefined },
  user: undefined,
  markAsUserLoggedIn: undefined,
  logout: undefined,
});

export function AuthProvider({ children }: { children: ReactNode }) {
  const [state, setState] = useState({
    status: 'pending',
    error: { message: undefined },
    user: { username: '' },
  });

  const [cookies, setCookie, removeCookie] = useCookies();
  React.useEffect(() => {
    setState(s => ({ ...s, status: 'loading' }));
  }, []);

  const logout = useCallback(() => {
    setState(s => ({
      ...s,
      token: undefined,
      status: 'logout',
      error: { message: undefined },
      profile: undefined,
    }));
    removeCookie('authToken');
  }, [removeCookie]);

  const markAsUserLoggedIn = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (googleUser: any) => {
      if (googleUser && googleUser.getBasicProfile) {
        const profile = googleUser.getBasicProfile();
        const email = profile.getEmail();
        if (
          [
            '4b48fae4cb9fa1b34b4a27a533d9f1a1',
            '5fbb621af0d37b8fc9115700b6464a3f',
            'c9a1f2e9fd6ce7c96af6a8832e0d59ab',
          ].includes(md5(email))
        ) {
          const r = googleUser.getAuthResponse();
          const token = r.id_token;
          setCookie('authToken', token);
          setState(s => ({
            ...s,
            token,
            status: 'success',
            error: { message: undefined },
            profile: {
              name: profile.getName(),
              avatar: profile.getImageUrl(),
            },
          }));
        } else {
          setState(s => ({ ...s, status: 'error' }));
        }
      }
    },
    [setCookie],
  );

  const value = useMemo(
    () => ({
      markAsUserLoggedIn,
      logout,
      ...state,
      cookies,
    }),
    [markAsUserLoggedIn, state, logout, cookies],
  );

  return (
    <AuthContext.Provider value={value}>
      {state.status === 'pending' ? (
        'Loading...'
      ) : state.status === 'error' ? (
        <div>
          Oh no
          <div>
            <pre>{state.error.message}</pre>
          </div>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}

export function useAuthState() {
  const state = useContext(AuthContext);
  const isPending = state.status === 'pending';
  const isError = state.status === 'error';
  const isSuccess = state.status === 'success';
  const isAuthenticated = state.user && isSuccess;
  return {
    ...state,
    isPending,
    isError,
    isSuccess,
    isAuthenticated,
    markAsUserLoggedIn: state.markAsUserLoggedIn,
    profile: state.profile,
    logout: state.logout,
  };
}
