import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useRouteMatch, Link } from 'react-router-dom';
import Modal from '../../components/Modal';
import AddOrEditServiceableArea from '../add-or-edit-serviceable-area';
import { Trans } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ServiceableArea: FC<any> = ({ data }) => {
  const { name, isDeleted, longitude, latitude, description, allowedDisplacementInMeters,allowedPinCodes } = data;
  const match = useRouteMatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState();

  function openModal() {
    setIsOpen(true);
    setModalData(data);
  }

  function closeModal() {
    setIsOpen(false);
    setModalData(undefined);
  }

  return (
    <>
      <Modal open={modalIsOpen} closeModal={closeModal}>
        <AddOrEditServiceableArea data={modalData} close={closeModal} />
      </Modal>

      <tr>
        <td>{name}</td>
        <td>{description}</td>
        <td>{latitude}</td>
        <td>{longitude}</td>
        <td>{allowedDisplacementInMeters}</td>
        <td>{(allowedPinCodes || []).join(',')}</td>
        <td>
          <label className={`badge badge-${isDeleted ? 'danger' : 'success'}`}>
            {isDeleted ? 'Deleted' : 'Active'}
          </label>
        </td>
        <td>
          <button className="btn btn-dark btn-fw btn-sm" onClick={openModal}>
            edit
          </button>
        </td>
      </tr>
    </>
  );
};

export default ServiceableArea;
