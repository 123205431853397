import React, { FC, useState } from 'react';
import './index.css';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';

const UPDATE_AREA = gql`
  mutation updateServiceableArea($input: UpdateServiceableAreaInput!) {
    updateServiceableArea(input: $input) {
      id
    }
  }
`;

const CREATE_AREA = gql`
  mutation createServiceableArea($input: CreateServiceableAreaInput!) {
    createServiceableArea(input: $input) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AddOrEditServiceableArea: FC<any> = ({ data, close }) => {
  const [updateReq] = useMutation(UPDATE_AREA, { errorPolicy: 'all' });
  const [createReq] = useMutation(CREATE_AREA, { errorPolicy: 'all' });
  const isUpdate = data.id !== undefined;
  const { register, handleSubmit, errors, getValues } = useForm({
    defaultValues: {
      allowedPinCodes: (data.allowedPinCodes || []).map(e => ({ pinCode: e })),
    },
  });
  const [indexes, setIndexes] = useState<Array<number>>(
    data.allowedPinCodes ? data.allowedPinCodes.map((_, i) => i) : [],
  );
  const [counter, setCounter] = useState<number>(
    data.allowedPinCodes?.length > 0 ? data.allowedPinCodes.length + 1 : 0,
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    const input = {
      id: data.id,
      ...(isUpdate && {
        isDeleted:
          values.isDeleted === 'true' || values.isDeleted === true
            ? true
            : false,
      }),
      ...(!isUpdate && {
        name: values.name,
      }),
      latitude: +values.latitude,
      longitude: +values.longitude,
      allowedPinCodes: (values.allowedPinCodes || [])
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter((entry: any) => entry.pinCode !== undefined)
        .filter((entry: any) => entry.pinCode !== '')
        .map((entry: any) => +entry.pinCode),
      allowedDisplacementInMeters: +values.allowedDisplacementInMeters,
      description: values.description,
    };
    if (isUpdate) {
      const result = await updateReq({
        variables: {
          input,
        },
      });
      if (
        result.data &&
        result.data.updateServiceableArea &&
        result.data.updateServiceableArea.id
      ) {
        console.log('result', result);
        close();
      }
    } else {
      const result = await createReq({
        variables: {
          input,
        },
      });
      if (
        result.data &&
        result.data.createServiceableArea &&
        result.data.createServiceableArea.id
      ) {
        console.log('result', result);
        close();
      }
    }
  };

  const addFriend = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setIndexes((prevIndexes: any) => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
  };

  const removeFriend = (index: number) => () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setIndexes((prevIndexes: any) => [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...prevIndexes.filter((item: any) => item !== index),
    ]);
    setCounter(prevCounter => prevCounter - 1);
  };

  const clearFriends = () => {
    setIndexes([]);
  };

  return (
    <section>
      <div className="container">
        <div className="contactinfo">
          <div>
            <span style={{ color: '#fff' }}>
              {isUpdate ? 'Edit Area:' : 'Create Area:'}
            </span>
            <h2> {data.name}</h2>

            <div className="slots-container"></div>
          </div>
        </div>
        <div className="contactForm">
          <div className="formBox">
            <div className="inputBox w50  ">
              <input
                type="text"
                name="name"
                ref={register({ required: true, maxLength: 31 })}
                defaultValue={data.name}
                disabled={isUpdate}
                maxLength={30}
              />

              <span>Name</span>
            </div>

            <div className="inputBox w50  ">
              <input
                type="text"
                name="description"
                ref={register({ required: false, maxLength: 31 })}
                defaultValue={data.description}
                maxLength={30}
              />

              <span>Description</span>
            </div>

            <div className="inputBox w50  ">
              <input
                type="text"
                name="latitude"
                ref={register({ required: true })}
                defaultValue={data.latitude}
              />

              <span>Latitude</span>
            </div>

            <div className="inputBox w50  ">
              <input
                type="text"
                name="longitude"
                ref={register({ required: true })}
                defaultValue={data.longitude}
              />

              <span>Longitude</span>
            </div>

            <div className="inputBox w50  ">
              <input
                type="text"
                name="allowedDisplacementInMeters"
                ref={register({ required: true })}
                defaultValue={data.allowedDisplacementInMeters}
              />

              <span>Covered Displacement (in meters)</span>
            </div>

            {isUpdate && (
              <div className="inputBox w100  ">
                <input
                  type="checkbox"
                  placeholder="Delete this"
                  name="isDeleted"
                  className="styled-checkbox"
                  ref={register}
                  id="styled-checkbox-2"
                  defaultChecked={data.isDeleted}
                />
                <label htmlFor="styled-checkbox-2">
                  {data.isDeleted ? 'Enable' : 'Delete'}
                </label>
              </div>
            )}

            <div className="postal-code-container">
              <h6>Allowed PinCodes</h6>
              {indexes.map((index: number) => {
                const fieldName = `allowedPinCodes[${index}]`;
                const fldErrors = errors?.allowedPinCodes
                  ? errors?.allowedPinCodes[index]
                  : {};
                return (
                  <fieldset name={fieldName} key={fieldName} className="slot">
                    <label>
                      PinCode ({fldErrors?.pinCode?.message}
                      )
                      <input
                        type="text"
                        name={`${fieldName}.pinCode`}
                        ref={register({
                          pattern: {
                            value: /^(\d{6})$/,
                            message: 'Provide only num',
                          },
                        })}
                      />
                    </label>

                    <button type="button" onClick={removeFriend(index)}>
                      Remove
                    </button>
                  </fieldset>
                );
              })}

              <div
                className="inputBox w100  "
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <button type="button" onClick={addFriend}>
                  Add
                </button>
                {indexes.length > 0 && (
                  <button type="button" onClick={clearFriends}>
                    Clear
                  </button>
                )}
              </div>
            </div>

            <div
              className="inputBox w100  "
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <button onClick={handleSubmit(onSubmit)}>save</button>
              <button onClick={close}>close</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddOrEditServiceableArea;
